html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade_in {
  opacity: 0;
  transition: opacity 2s;
}
.fade_in--loaded {
  opacity: 1;
}

img {
  max-width: 100%;
}

